<template>
    <div class="invite_email">
        <p class="invite-details">
            Or email the link directly to the people you know.
        </p>
        <div class="invite-wrapper-form">
            <div class="invite_link_copy">
                <form-input
                    v-model.trim="emailValue"
                    legend="Enter email address"
                    prepend-icon="EmailOutlineIcon"
                    type="text"
                    name="email"
                    :field.sync="emailValue"
                    :errors="validationMessage($v.email)"
                    :is-valid="$v.email.$dirty && !$v.email.$anyError"
                    :loading="loadEmail"
                    @input.native="$v.email.$touch()"
                    @blur.native="$v.email.$touch()"
                    @onChange="onLoadEmail($event)"
                />
            </div>
            <custom-button
                class="invite_link_copy__btn btn-base btn-main invite_link_copy__btn--invite"
                :loading="isLoading"
                @on-btn-click="openConfirmModal('Email invitation to a friend', `We are going to send an e-mail invitation to ${emailValue}. Are you sure?`)"
            >
                <span class="invite_widget-btn__title">Invite</span>
                <WidgetInvite />
            </custom-button>
        </div>
        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { validationMixin } from 'vuelidate'
import { createNamespacedHelpers } from 'vuex';
import { validationMessage } from 'vuelidate-messages'
import formValidationMixin from '@/mixins/formValidationMixin'
import { formMessages } from '@/validation/friends/Messages'
import { validationRules } from '@/validation/friends/Rules.js'
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_CLIENT_FRIEND_ADD
} from '@/store/modules/client/action-types'

import ConfirmModal from '@/components/modals/ConfirmModal';
import WidgetInvite from '@/components/icons/InviteWidget/WidgetInvite.vue';

const { mapActions: mapClientActions } = createNamespacedHelpers('client');
export default {
    components: {
        WidgetInvite,
        ConfirmModal
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            emailValue: '',
            email: '',
            loadEmail: false,
            isLoading: false
        }
    },
    validations: {
        ...validationRules
    },
    watch: {
        emailValue: debounce(function (newEmail) {
            this.email = newEmail.trim()
            this.loadEmail = false
        }, 300)
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions([
            ACTION_CLIENT_FRIEND_ADD
        ]),
        openConfirmModal(title, message) {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            this.$refs['confirm-modal'].open(title, message)
                .then((res) => {
                    if (res) {
                        this.addFriend()
                    }
                })
        },
        async addFriend() {
            try {
                this.isLoading = true
                await this[ACTION_CLIENT_FRIEND_ADD]({
                    email: this.email
                })
                this.emailValue = ''
                this.email = ''
                this.$v.$reset()
                eventBus.$emit('showSnackBar', 'Email was sent.', 'success');
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            } finally {
                this.isLoading = false
            }
        },
        onLoadEmail(value) {
            if (this.email === value.trim()) {
                this.loadEmail = false
                return
            }
            this.loadEmail = true
        },
        cancelChanges() {
            this.$router.push({ name: 'friends' })
        }
    }
}
</script>
