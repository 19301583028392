import { checkEmailExists } from '@/services/validationsHelper';
import {
    required,
    email
} from 'vuelidate/lib/validators';

export const validationRules = {
    email: {
        required,
        email,
        async emailExists(value) {
            // ignore this validator, if any of the previous fail
            if (!this.$v.email.required || !this.$v.email.email) { return true; }

            try {
                await checkEmailExists(value)
                return true
            } catch (error) {
                return false
            }
        }
    }
};
