<template>
    <div class="invite_link">
        <p class="invite-details">
            Share the link with friends or post it on social media.
        </p>
        <div class="invite-details__wrapper">
            <div class="invite_link_copy invite_link_copy--social">
                <button
                    class="invite_link_copy__btn btn-base btn-main invite_link_copy__btn--copy"
                    @click="copyToClickboard"
                >
                    <WidgetCopy />
                </button>
                <form-input
                    v-model="referalLink"
                    legend="Referal link"
                    prepend-icon="LinkIcon"
                    type="text"
                    name="email"
                    :field.sync="referalLink"
                    :disabled="true"
                />
            </div>
            <div class="invite__share">
                <button
                    class="invite-btn"
                    @click="shareFacebook"
                >
                    <WidgetFacebook />
                </button>
                <button
                    class="invite-btn"
                    @click="shareTwitter"
                >
                    <WidgetTwitter />
                </button>
                <button
                    class="invite-btn"
                    @click="shareLinkedin"
                >
                    <WidgetLinkedin />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import { mapGetters } from 'vuex'

import WidgetCopy from '@/components/icons/InviteWidget/WidgetCopy.vue';
import WidgetFacebook from '@/components/icons/InviteWidget/WidgetFacebook.vue';
import WidgetTwitter from '@/components/icons/InviteWidget/WidgetTwitter.vue';
import WidgetLinkedin from '@/components/icons/InviteWidget/WidgetLinkedin.vue';

export default {
    components: {
        WidgetLinkedin,
        WidgetTwitter,
        WidgetFacebook,
        WidgetCopy
    },
    computed: {
        ...mapGetters('client', [
            'getReferralId'
        ]),
        referalLink() {
            return `${process.env.VUE_APP_API_URL}/referral/${this.getReferralId}`
        }
    },
    methods: {
        copyToClickboard() {
            navigator.clipboard.writeText(this.referalLink)
            eventBus.$emit('showSnackBar', 'The link was copied!', 'success');
        },
        shareFacebook() {
            const SHARE_URL = 'https://www.facebook.com/sharer/sharer.php'
            const params = {
                u: this.referalLink,
                hashtag: 'writing help',
                quote: 'hire a personal writer on this site'
            }
            this.openSharePage(`${SHARE_URL}?${this.objectToQuery(params)}`)
        },
        shareTwitter() {
            const SHARE_URL = 'https://twitter.com/intent/tweet/'
            const params = {
                text: 'hire a personal writer on this site',
                url: this.referalLink,
                hashtags: 'writing help',
                via: 'elonmusk'
            }
            this.openSharePage(`${SHARE_URL}?${this.objectToQuery(params)}`)
        },
        shareLinkedin() {
            const SHARE_URL = 'https://www.linkedin.com/shareArticle'
            const params = {
                url: this.referalLink,
                mini: true
            }
            this.openSharePage(`${SHARE_URL}?${this.objectToQuery(params)}`)
        },
        openSharePage(link) {
            const popWidth = 600;
            const popHeight = 480;
            const left = window.innerWidth / 2 - popWidth / 2 + window.screenX;
            const top = window.innerHeight / 2 - popHeight / 2 + window.screenY;
            const popParams = `scrollbars=no, width=${popWidth}, height=${popHeight}, top=${top}, left=${left}`;
            const newWindow = window.open(link, '', popParams);

            if (window.focus) {
                newWindow.focus();
            }
        },
        objectToQuery(params) {
            return Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
        }
    }
}
</script>
